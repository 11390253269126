<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-domain"
            title="Accounts"
        >
          <v-icon class="section-helper" @click="openHelpLink('accounts')">mdi-help-circle</v-icon>
          <v-row no-gutters>
            <v-col cols="12" md="12">

              <data-grid
                  :envSelected="envSelected"
                  :grid="grid"
                  @doAction="gridAction"
                  @paginate="listItems"
              >
                <template v-slot:column-name="{ item }">
                  <div class="font-weight-medium">{{ item.name }}</div>
                </template>

                <template v-slot:column-groups="{ item }">
                  <span class="font-weight-medium" v-for="(oneGroup, index) in item.groups" :key="index">
                    <v-hover
                        v-slot:default="{ hover }"
                        open-delay="50"
                    >
                      <v-chip
                          @click="goToGroup(oneGroup)"
                          class="ma-2"
                          :color="hover? 'primary white--text' : ''"
                      >
                      <v-avatar left>
                        <v-icon>mdi-account-group</v-icon>
                      </v-avatar>
                      {{ oneGroup }}
                      <help-circle :context="help.groups"></help-circle>
                    </v-chip>
                    </v-hover>
                  </span>
                </template>

                <template v-slot:column-status="{ item }">
                  <v-chip small label :color="item.status === 'active' ? 'success' : 'warning'">
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:column-expanded="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="ma-5" v-if="grid.fieldAccess.tenants">
                      <template v-if="item.tenants && item.tenants.length > 0">
                        <h4>Applications</h4>
                        <span class="font-weight-medium" v-for="(oneTenant, index) in item.tenants" :key="index">
                    <v-hover
                        v-slot:default="{ hover }"
                        open-delay="50"
                    >
                      <v-chip
                          class="mt-2 mb-2 mr-2"
                          label
                          :color="hover ? 'primary white--text': 'info'"
                          @click="goToTenant(oneTenant)"
                      >
                        <v-avatar left>
                          <v-icon>mdi-apps</v-icon>
                        </v-avatar>
                        {{ oneTenant.code }}
                        <help-circle :context="help.tenants"></help-circle>
                      </v-chip>
                    </v-hover>
                </span>
                      </template>
                      <template v-else>
                        <p class="font-italic font-weight-light">No Applications linked to this account.</p>
                      </template>
                    </div>
                  </td>
                </template>

              </data-grid>

              <custom-dialog :dialog="usersDialog" :env-selected="envSelected" :do-action="updateAccountUsers">
                <v-row v-if="usersDialog.data">
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="usersDialog.data.account.users"
                        :items="usersDialog.data.users"
                        :loading="usersDialog.loading"
                        :search-input.sync="usersDialog.search"
                        label="Users"
                        :hint="`Select the users of this group`"
                        item-value="id"
                        item-text="label"
                        hide-no-data
                        hide-selected
                        placeholder="Type the name of the user you want to add"
                        prepend-icon="mdi-account-multiple"
                        return-object
                        multiple
                        persistent-hint
                        chips
                        deletable-chips
                        clearable
                        @change="usersDialog.search = ''"
                    >
                      <template v-slot:selection="{ index, item }">
                        <v-chip color="primary" v-if="index === 0">
                          {{ item.label }}
                        </v-chip>
                        <span
                            v-if="index === 1"
                            class="grey--text caption"
                        >
                          (+{{
                            usersDialog.data.account.users.length - 1
                          }} other{{ usersDialog.data.account.users.length > 2 ? 's' : '' }})
                        </span>
                      </template>
                    </v-autocomplete>
                    <v-expansion-panels class="mt-5 mx-0" focusable
                                        v-if="usersDialog.data.account.users && usersDialog.data.account.users.length > 0">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Users in this Account ( {{ usersDialog.data.account.users.length }} )
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-4">
                          <v-chip
                              label
                              color="primary"
                              class="ma-1"
                              v-for="(user, i) in usersDialog.data.account.users"
                              :key="i"
                          >
                            <v-icon class="mr-1">mdi-account</v-icon>
                            {{ user.label }}
                            <!--                            <v-icon class="ml-2" @click="removeUsersFromAccount(user)" right>-->
                            <!--                              mdi-delete</v-icon>-->
                          </v-chip>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </custom-dialog>

            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpCircle from "@/views/internal/components/custom/helpCircle";
import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import CustomDialog from "@/views/internal/components/custom/dialog";

export default {
  name: 'Accounts',
  data: () => ({
    help: {
      groups: {
        right: true,
        text: `User Security Group`,
        link: 'groups'
      },
      tenants: {
        bottom: true,
        text: `Accessible Multitenant Applications`,
        link: 'tenants'
      }
    },
    grid: {
      fieldAccess: {},
      //mandatory
      headers: [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Group(s)',
          value: 'groups',
        },
        {
          align: 'right',
          text: 'Actions',
          value: 'actions',
        },
      ],
      items: [],
      selected: [],
      //optional
      loading: true,
      //optional
      columns: [
        {
          name: 'item.name'
        },
        {
          name: 'item.groups'
        },
        {
          name: 'item.expanded'
        }
      ],
      //optional
      search: {
        keyword: ''
      },
      //optional
      itemID: 'id',
      //optional
      expand: {
        single: false,
        items: []
      },
      //optional
      multi: [
        {
          icon: 'delete',
          label: 'Delete',
          color: 'error',
          acl: {method: 'delete', route: `/organization/accounts/:id`},
          method: 'deleteAccount',
          //optional
          confirm: `Are you sure you want to delete these Account(s)?`
        }
      ],
      //optional
      sortingList: ['name'],
      sort: ['name', 1],
      //optional
      headActions: [
        {
          icon: 'refresh',
          label: '',
          color: 'purple',
          acl: {method: 'get', route: `/organization/accounts`},
          method: 'listItems'
        },
        {
          icon: 'plus',
          label: 'create',
          color: 'success',
          acl: {method: 'put', route: `/organization/accounts`},
          method: 'addAccount'
        }
      ],
      //optional
      rowActions: [
        {
          method: 'addUsersToAccount',
          color: 'warning',
          icon: 'mdi-account-group',
          allow: {
            method: 'patch',
            route: '/organization/accounts/:id/users'
          },
          tooltip: 'Add/Remove Users'
        },
        {
          method: 'enableMfa',
          color: 'error',
          icon: 'mdi-two-factor-authentication',
          condition: {
            field: 'mfa',
            value: false
          },
          allow: {
            method: 'patch',
            route: '/organization/accounts/:id'
          },
          tooltip: "Enable MFA"
        },
        {
          method: 'disableMfa',
          color: 'blue',
          icon: 'mdi-two-factor-authentication',
          condition: {
            field: 'mfa',
            value: true
          },
          allow: {
            method: 'patch',
            route: '/organization/accounts/:id'
          },
          tooltip: "Disable MFA"
        },
        {
          method: 'editAccount',
          color: 'success',
          icon: 'mdi-pencil',
          allow: {
            method: 'patch',
            route: '/organization/accounts/:id'
          },
          tooltip: 'Edit'
        },
        {
          method: 'deleteAccount',
          color: 'error',
          icon: 'mdi-delete',
          allow: {
            method: 'delete',
            route: '/organization/accounts/:id'
          },
          tooltip: 'Delete'
        },
      ]
    },
    usersDialog: {
      title: '',
      search: '',
      trigger: false,
      loading: false,
      data: null,
      actions: [
        {
          method: 'updateAccountUsers',
          icon: 'content-save',
          color: 'success',
          label: 'Update Account',
          allow: {
            method: 'patch',
            route: '/organization/accounts/:id/users'
          }
        }
      ]
    },
  }),
  mixins: [globalMixins, fieldsMixins],
  props: {
    envSelected: {
      type: Object
    }
  },
  components: {
    dataGrid,
    CustomDialog,
    HelpCircle
  },

  created() {
    this.$nextTick(() => {
      this.grid.fieldAccess = this.accountListAccess();
    });
  },

  methods: {

    async listItems(page, limit, sort) {
      this.grid.loading = true;
      this.grid.items = [];
      if (page !== undefined) {
        this.grid.page = page;
      }
      if (limit !== undefined) {
        this.grid.limit = limit;
      }
      if (sort !== undefined) {
        this.grid.sort = sort;
      }

      const accounts = await this.getSendData({
        noLoading: true,
        url: `/consoleapi/organization/accounts`,
        method: "get",
        params: {
          offset: (this.grid.page !== undefined) ? this.grid.page : 1,
          limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
          sort: this.grid.sort
        }
      });

      this.grid.items = accounts.items || [];
      this.grid.total = accounts.total || 1;
      this.grid.perPage = this.grid.limit;
      this.grid.items.forEach(function (one) {
        one.mfa = false;
        if (one.security && one.security.mfa) {
          one.mfa = one.security.mfa;
        }
      });
      this.grid.loading = false;
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    addAccount() {
      this.goToPage({route: 'addAccount'});
    },

    editAccount(item) {
      this.goToPage({route: 'editAccount', params: {id: item.id}});
    },

    deleteAccount(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to delete account: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/organization/accounts/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions)
            .then(() => {
              _self.pushMessage({
                type: 'success',
                title: `Account Deleted`,
                text: `This account has been deleted.`
              });
              setTimeout(() => {
                _self.listItems();
              }, 2000);
            });
      }
    },

    goToUser(oneUser) {
      this.goToPage({route: 'editUser', params: {id: oneUser.id}});
    },

    goToGroup(oneGroup) {
      this.goToPage({route: 'editGroup', params: {code: oneGroup}});
    },

    goToTenant(oneTenant) {
      this.goToPage({route: 'editTenantApplication', params: {id: oneTenant.id}});
    },

    async addUsersToAccount(item) {
      this.usersDialog.loading = true;
      let myAccount = this._lodash.cloneDeep(item);
      this.listUsers(myAccount, (users) => {
        this.usersDialog.loading = false;
        // this.usersDialog.search = '';
        this.usersDialog.width = 800;
        this.usersDialog.title = 'Add/Remove Account User(s)';
        this.usersDialog.data = {
          original: item,
          account: myAccount,
          users: users
        };
        this.usersDialog.trigger = true;
        this.usersDialog.itemId = myAccount.id;
      });
    },

    async searchUsers(search) {
      this.usersDialog.loading = true;
      this.usersDialog.data.users = [];
      this.listUsers(this.usersDialog.data.original, (users) => {
        this.usersDialog.data.users = users;
        this.usersDialog.loading = false;
      });
    },

    removeUsersFromAccount(user) {
      if (confirm(`Are you sure you want to remove user '${user.label}' from this account ?`)) {
        let users = [...this.usersDialog.data.account.users];
        this.usersDialog.data.account.users = [];
        for (let i = users.length - 1; i >= 0; i--) {
          if (users[i].id === user.id) {
            users.splice(i, 1);
          }
        }
        this.$nextTick(() => {
          this.usersDialog.data.account.users = users;
        });
      }
    },

    async listUsers(myAccount, cb) {
      let data = {items: []};
      if (this.usersDialog.search && this.usersDialog.search.trim() !== '') {
        data = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/organization/users`,
          method: "get",
          params: {
            search: this.usersDialog.search,
            fields: ['firstName', 'lastName', 'email', 'account'],
            pagination: false
          }
        });
      } else {
        data = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/organization/users`,
          method: "get",
          params: {
            fields: ['firstName', 'lastName', 'email', 'account'],
            pagination: false
          }
        });
      }

      let users = data.items || [];
      myAccount.users = [];

      users.forEach((oneUser) => {
        if (oneUser.account === myAccount.id) {
          myAccount.users.push({
            id: oneUser.id,
            label: `${oneUser.firstName} ${oneUser.lastName} < ${oneUser.email} >`
          });
        }
      });

      for (let i = 0; i < users.length; i++) {
        users[i] = {
          id: users[i].id,
          label: `${users[i].firstName} ${users[i].lastName} < ${users[i].email} >`
        };
      }
      return cb(users);
    },

    updateAccountUsers(value, item) {
      const _self = this;
      let users = this._lodash.cloneDeep(this.usersDialog.data.account.users);
      let myAccount = this._lodash.cloneDeep(this.usersDialog.data.original)
      myAccount.users = [];
      users.forEach((oneUser) => {
        myAccount.users.push(oneUser.id);
      });

      const apiOptions = {
        url: `/consoleapi/organization/accounts/${myAccount.id}/users`,
        method: "patch",
        params: {
          "users": myAccount.users
        }
      };

      _self.getSendData(apiOptions)
          .then(() => {
            _self.pushMessage({
              type: 'success',
              title: `Group Updated`,
              text: `The list of users in group ${myAccount.name} has been updated.`
            });
            this.usersDialog.trigger = false;
            setTimeout(() => {
              _self.listItems();
            }, 2000);
          }).catch(() => {
        this.usersDialog.trigger = false;
      });
    },

    doMfaChange(apiOptions) {
      const _self = this;
      _self.getSendData(apiOptions)
          .then(() => {
            _self.pushMessage({
              type: 'success',
              title: `User Updated`,
              text: `The user has been updated.`
            });
            setTimeout(() => {
              _self.listItems();
            }, 2000);
          });
    },

    disableMfa(item) {
      const _self = this;
      let security = {};
      if (item.security) {
        security = item.security;
      }
      security.mfa = false;
      const apiOptions = {
        url: `/consoleapi/organization/accounts/${item.id}`,
        method: "patch",
        "params": {
          "mfa": false
        }
      };
      if (confirm(`Are you sure you want DISABLE MFA for: ${item.name}`)) {
        _self.doMfaChange(apiOptions);
      }
    },

    enableMfa(item) {
      const _self = this;
      let security = {};
      if (item.security) {
        security = item.security;
      }
      security.mfa = true;
      const apiOptions = {
        url: `/consoleapi/organization/accounts/${item.id}`,
        method: "patch",
        "params": {
          "mfa": true
        }
      };
      if (confirm(`Are you sure you want to ENABLE MFA for: ${item.name}`)) {
        _self.doMfaChange(apiOptions);
      }

    },
  },

  watch: {
    async 'usersDialog.search'(val) {
      if (val && val.length >= 3) {
        this.usersDialog.search = val;
        await this.searchUsers(val);
      }
    }
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
